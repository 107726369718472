<template>
  <div
    class="z-profile-image border"
    :class="{ sm: size === 'sm', lg: size === 'lg' }"
  >
    <z-image
      v-if="image && !imageError"
      :src="image"
      class="image "
      @image-error="imageError = true"
    />
    <font-awesome-icon
      v-else
      :icon="icon"
      :flip="icon === 'helmet' ? 'horizontal' : undefined"
      class="image icon"
    />
  </div>
</template>

<script>
import ZImage from "@/app/components/ZImage";
import BusinessLine from "@/constants/clients/businessLine";

export default {
  name: "ZProfileImage",

  components: {
    ZImage
  },

  props: {
    size: {
      type: String,
      default: "sm",
      validator: val => ["sm", "lg"].indexOf(val) > -1
    },
    type: {
      type: String,
      default: "user",
      validator: val => ["user", "driver"].indexOf(val) > -1
    },
    businessLine: {
      type: Number,
      default: null
    },
    image: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      imageError: false
    };
  },

  computed: {
    icon() {
      if (this.type === "driver") {
        return "helmet";
      } else {
        switch (this.businessLine) {
          case BusinessLine.NUM_STORE:
            return "business";
          case BusinessLine.NUM_RESTAURANT:
            return "store-alt";
          default:
            return "user";
        }
      }
    },
    showImage() {
      return this.image && !this.imageError;
    }
  }
};
</script>

<style lang="scss" scoped>
$border-radius: 8px;

.z-profile-image {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: $border-radius;

  .image {
    height: 85%;
    width: 85%;
    border-radius: $border-radius;
  }
  .icon {
    background-color: darken($white-smoke, 5%);
    padding: 5px;
  }

  &.sm {
    width: 40px;
    height: 40px;
    font-size: 1.8rem;
  }

  &.lg {
    width: 94px;
    height: 94px;
    font-size: 4rem;
  }
}
</style>
